<template>
    <v-card flat>
        <v-card-title>
            <div class="mb-3 d-flex flex-column">
                <div class="d-flex align-center">
                    <span class="primary--text font-weight-bold">Snapshots</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                :loading="fetchingSnapshots"
                                v-on="on"
                                @click="$store.dispatch('instanceStore/fetchInstanceSnapshots', $route.params.iid)"
                            >
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh snapshots</span>
                    </v-tooltip>
                </div>
                <span v-if="fetchingSnapshots" class="caption text-uppercase">Fetching instance data....</span>
                <span v-else-if="nonDevelopmentSnapshots.length" class="caption text-uppercase ma-0">{{ nonDevelopmentSnapshots.length }} SNAPSHOTS FOUND</span>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="nonDevelopmentSnapshots.length > 0 && fetchingSnapshots === false">
                <v-row justify="space-between">
                    <v-col v-for="(item, index) in Object.values(snapshotCheckboxBinders)" :key="index" cols="12" md="3">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-checkbox class="ma-0 pa-0" v-on="on" v-model="item.status">
                                    <template v-slot:label>
                                        <div class="d-flex align-center">
                                            <v-icon :color="item.color">fiber_manual_record</v-icon>
                                            <span class="primary--text caption text-uppercase">{{ item.text }}</span>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </template>
                            <span>{{ item.description }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-divider class="mb-5"></v-divider>
                <v-timeline v-if="nonDevelopmentSnapshots.length > 0" reverse>
                    <v-timeline-item :fill-dot="true" right :color="dotColor(snapshot)" v-for="snapshot in filteredSnapshots" :key="snapshot.snid" small>
                        <div class="d-flex justify-space-between flex-wrap" slot="opposite">{{ snapshot.snapshot_timestamp | dateTimeToHuman }} (UTC)</div>
                        <v-card class="elevation-1">
                            <v-card-title>
                                <div style="width:100%" class="d-flex justify-space-between">
                                    <router-link
                                        :to="{
                                            name: 'snapshot-overview',
                                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: snapshot.snid }
                                        }"
                                        style="text-decoration: none;"
                                    >
                                        <v-hover v-slot:default="{ hover }">
                                            <span :class="[hover ? 'secondary--text' : 'primary--text', 'mr-2', 'subtitle-1', 'font-weight-bold']">{{
                                                snapshot.long_id
                                            }}</span>
                                        </v-hover>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" :color="snapshot.filesystem_prefix ? 'success' : 'info'" small>{{
                                                    getSnapshotStatusIcon(snapshot)
                                                }}</v-icon>
                                            </template>
                                            <div style="max-width:500px">
                                                {{ getSnapshotStatusDescription(snapshot) }}
                                            </div>
                                        </v-tooltip>
                                    </router-link>
                                    <div v-if="isInstanceEditor === true" class="d-flex align-center">
                                        <SnapshotRestoreDialog v-if="!isDistributedInstance" :snapshotData="snapshot" :isRestoreToCurrentInstance="true" />
                                        <SnapshotRestoreDialog v-if="!isDistributedInstance" :snapshotData="snapshot" :isRestoreToCurrentInstance="false" />
                                        <SnapshotDeleteDialog :snapshotData="snapshot" />
                                    </div>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                {{ snapshot.description }}
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </div>
            <div v-else-if="fetchingSnapshots">
                <div
                    v-for="item in [
                        { id: 'c1', opacity: 1 },
                        { id: 'c2', opacity: 0.75 },
                        { id: 'c3', opacity: 0.5 }
                    ]"
                    :key="item.id"
                >
                    <div :style="{ opacity: item.opacity }">
                        <v-skeleton-loader type="list-item-three-line" class="mx-auto" />
                    </div>
                </div>
            </div>

            <v-banner v-else-if="fetchingSnapshots === false && nonDevelopmentSnapshots.length === 0" two-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">
                        info
                    </v-icon>
                </v-avatar>
                <div style="max-width:900px" class="d-flex flex-column">
                    <span class="font-weight-bold">No snapshots found</span>
                    <span class="mt-1">
                        Snapshots are immutables states of previous work that allow you to reproduce results and restore files, applications, and data. Check
                        the
                        <a href="https://docs.nuvolos.cloud/getting-started/working-with-snapshots/create-a-snapshot" target="_blank">documentation here</a>
                        for information on how to create a snapshot.
                    </span>
                </div>
            </v-banner>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { sortArray, truncateText } from '@/utils'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'
const SnapshotDeleteDialog = () => import('@/modules/snapshot/components/TheSnapshotDeleteDialog')
const SnapshotRestoreDialog = () => import('@/modules/snapshot/components/TheSnapshotRestore')
export default {
    mixins: [enumsData, snapshotEnums],
    components: { SnapshotDeleteDialog, SnapshotRestoreDialog },
    data() {
        return {
            snapshotCheckboxBinders: {
                quickSnapshots: {
                    status: true,
                    color: 'blue',
                    text: 'Quick Snapshots',
                    description: 'Snapshots saved by the user via the Quick Snapshot action'
                },
                autoSnapshots: {
                    status: true,
                    color: 'yellow darken-1',
                    text: 'Auto Snapshots',
                    description: 'Snapshots saved by Nuvolos when operations like object distribution or snapshot restoration are performed'
                },
                detailedSnapshots: {
                    status: true,
                    color: 'success',
                    text: 'Detailed Snapshots',
                    description: 'Snapshots created with a description by the user'
                }
            }
        }
    },
    methods: {
        snapshotOperation: function(sourceSnapshotId, operation) {
            const pars = {
                oid: this.$route.params.oid,
                sid: this.$route.params.sid,
                iid: this.$route.params.iid,
                snid: sourceSnapshotId
            }
            if (operation === 'restoreToCurrentInstance') {
                this.$router.push({
                    name: 'snapshot-restore',
                    params: pars
                })
            } else if (operation === 'restoreToAnotherInstance') {
                this.$router.push({
                    name: 'instance-upload-to-development',
                    params: pars
                })
            } else if (operation === 'deleteSnapshot') {
                this.$router.push({
                    name: 'snapshot-delete',
                    params: pars
                })
            }
        },
        truncateText: function(text, maxLength) {
            return truncateText(text, maxLength)
        },
        dotColor: function(snapshot) {
            if (snapshot.snapshot_type) {
                if (snapshot.snapshot_type === this.snapshotTypes.AUTO_SNAPSHOT) {
                    return 'yellow darken-1'
                } else if (snapshot.snapshot_type === this.snapshotTypes.QUICK_SNAPSHOT) {
                    return 'light-blue'
                } else if (snapshot.snapshot_type === this.snapshotTypes.DETAILED_SNAPSHOT) {
                    return 'success'
                } else {
                    return 'success'
                }
            } else {
                return 'primary'
            }
        }
    },
    computed: {
        ...mapState('instanceStore', ['fetchingSnapshots']),
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isDistributedInstance']),
        ...mapGetters('snapshotStore', ['nonDevelopmentSnapshots']),
        filteredSnapshots() {
            const snapshots = this.nonDevelopmentSnapshots.filter(
                snapshot =>
                    (snapshot.snapshot_type === this.snapshotTypes.QUICK_SNAPSHOT && this.snapshotCheckboxBinders.quickSnapshots.status === true) ||
                    (snapshot.snapshot_type === this.snapshotTypes.AUTO_SNAPSHOT && this.snapshotCheckboxBinders.autoSnapshots.status === true) ||
                    (snapshot.snapshot_type === this.snapshotTypes.DETAILED_SNAPSHOT && this.snapshotCheckboxBinders.detailedSnapshots.status === true)
            )
            return sortArray(snapshots, 'snid', 'descending', false)
        }
    }
}
</script>
